import React from 'react';
import Link from './Link';

const ProjectContainer = ({ children, lang, link, title, desc, client, background }) => {
    
    const makeLink = lang === 'en' ? `/our-work/${link}/` : `/${lang}/our-work/${link}/`;

    return (
        <div className="project__container">
            <Link lang={lang} to={makeLink} projectLink>
                <div className="project__container--overlay">
                    <div className="project__container--main">
                        <div className="project__container--title">
                            {title}
                        </div>
                        <div className="project__container--desc">
                            {client}
                        </div>
                    </div>
                </div>
                <div className="project__container--body" style={{ backgroundColor: background }}>
                    {children}
                </div>
            </Link>
        </div>
    )
}

export default ProjectContainer;