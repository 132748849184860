import React from 'react';

import { GoalIcon, DesignIcon, DevelopmentIcon, DeliveryIcon, SupportIcon, QualityIcon, MotivationIcon, DedicationIcon, DiscoverIcon } from './Icons';

const GetIcon = ({ icon }) => {
    switch(icon) {
        case 'GoalIcon':
            return <GoalIcon />
        case 'DedicationIcon':
            return <DedicationIcon />
        case 'MotivationIcon':
            return <MotivationIcon />
        case 'QualityIcon':
            return <QualityIcon />
        case 'DevelopmentIcon':
            return <DevelopmentIcon />
        case 'DeliveryIcon':
            return <DeliveryIcon />
        case 'SupportIcon':
            return <SupportIcon />
        case 'DesignIcon':
            return <DesignIcon />
        case 'DiscoverIcon':
            return <DiscoverIcon />
        default:
            return null;
    }
}

export default GetIcon;