export const CorporationSchema = {
  "@context": "https://schema.org",
  "@type": "Corporation",
  name: "Valudio",
  url: "https://www.valudio.com/",
  logo: "https://www.datocms-assets.com/38012/1673966129-valudio-logo-only.jpeg",
  contactPoint: {
    "@type": "ContactPoint",
    telephone: "+41 (0) 44 688 01 44",
    contactType: "sales",
    availableLanguage: ["en", "es", "fr", "de"],
  },
  sameAs: [
    "https://www.valudio.com/",
    "https://github.com/valudio",
    "https://www.linkedin.com/company/valudio/",
  ],
}

export const LocalBusinessSchema = {
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  name: "Valudio",
  image:
    "https://www.datocms-assets.com/38012/1673966129-valudio-logo-only.jpeg",
  "@id": "https://www.valudio.com",
  url: "https://www.valudio.com",
  telephone: "+41 (0) 44 688 01 44",
  email: "info@valudio.com",
  address: {
    "@type": "PostalAddress",
    streetAddress: "Hardturmstrasse 161",
    addressLocality: "Zürich",
    postalCode: "8050",
    addressCountry: "CH",
  },
  geo: {
    "@type": "GeoCoordinates",
    latitude: 47.3930926,
    longitude: 8.5119711,
  },
  sameAs: [
    "https://www.linkedin.com/company/valudio/",
    "https://github.com/valudio",
    "https://www.valudio.com/",
  ],
  department: [
    {
      "@type": "LocalBusiness",
      name: "Valudio - Zürich",
      legalName: "Valudio GmbH",
      vatID: "CHE-440.167.772",
      image:
        "https://www.datocms-assets.com/38012/1673966129-valudio-logo-only.jpeg",
      telephone: "+41 (0) 44 688 01 44",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Hardturmstrasse 161",
        addressLocality: "Zürich",
        postalCode: "8050",
        addressCountry: "CH",
      },
      geo: {
        "@type": "GeoCoordinates",
        latitude: 47.3930926,
        longitude: 8.5119711,
      },
      openingHoursSpecification: {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        opens: "08:00",
        closes: "18:00",
      },
    },
    {
      "@type": "LocalBusiness",
      name: "Valudio - Barcelona",
      legalName: "Valudio S.L.",
      vatID: "ESB66811183",
      image:
        "https://www.datocms-assets.com/38012/1673966129-valudio-logo-only.jpeg",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Av. de Francesc Cambó 17",
        addressLocality: "Barcelona",
        postalCode: "08003",
        addressCountry: "ES",
      },
      geo: {
        "@type": "GeoCoordinates",
        latitude: 41.38621,
        longitude: 2.1775,
      },
      openingHoursSpecification: {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        opens: "08:00",
        closes: "18:00",
      },
    },
  ],
}

export const FAQSchemaGenerate = (faq) => {
  const mainEntity = faq.map(item => ({
    "@type": "Question",
    name: `${item.question}`,
    acceptedAnswer: {
      "@type": "Answer",
      text: `${item.answer}`,
    },
  }))

  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: mainEntity,
  }
}
